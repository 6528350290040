import { Suspense, lazy } from 'react';
import DashboardLayout from './components/dashboard/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';

const Loadable = (Component) => (props) =>
	(
		<Suspense fallback={<LoadingScreen />}>
			<Component {...props} />
		</Suspense>
	);

// User pages
const UserRegister = Loadable(lazy(() => import('./pages/user/Register')));
const UserLogin = Loadable(lazy(() => import('./pages/user/Login')));
const UserPasswordRecovery = Loadable(
	lazy(() => import('./pages/user/PasswordRecovery'))
);
const UserPasswordReset = Loadable(
	lazy(() => import('./pages/user/PasswordReset'))
);
const MovieList = Loadable(lazy(() => import('./pages/user/MovieList')));
const MovieDetail = Loadable(lazy(() => import('./pages/user/MovieDetail')));

// Admin pages
const AdminLogin = Loadable(lazy(() => import('./pages/admin/Login')));
const UserList = Loadable(lazy(() => import('./pages/admin/UserList')));
const UserEdit = Loadable(lazy(() => import('./pages/admin/UserEdit')));
const UserRegist = Loadable(lazy(() => import('./pages/admin/UserRegist')));
const AdminList = Loadable(lazy(() => import('./pages/admin/AdminList')));
const AdminRegist = Loadable(lazy(() => import('./pages/admin/AdminRegist')));
const AdminEdit = Loadable(lazy(() => import('./pages/admin/AdminEdit')));
const AdminMovieList = Loadable(lazy(() => import('./pages/admin/MovieList')));
const AdminMovieDetail = Loadable(lazy(() => import('./pages/admin/MovieDetail')));
const MovieRegist = Loadable(lazy(() => import('./pages/admin/MovieRegist')));
const MovieEdit = Loadable(lazy(() => import('./pages/admin/MovieEdit')));
const UserViewList = Loadable(lazy(() => import('./pages/admin/UserViewList')));

const routes = [
	{
		path: '/',
		children: [
			{
				path: '',
				element: <UserLogin />,
			},
			{
				path: 'regist/:userId',
				element: <UserRegister />,
			},
			{
				path: 'login',
				element: <UserLogin />,
			},
			{
				path: 'passwordrecovery',
				element: <UserPasswordRecovery />,
			},
			{
				path: 'passwordreset/:passwordResetId',
				element: <UserPasswordReset />,
			},
		],
	},
	{
		path: '/',
		element: <MainLayout />,
		children: [
			{
				path: 'movie/list',
				element: <MovieList />,
			},
			{
				path: 'movie/detail/:id',
				element: <MovieDetail />,
			},
		],
	},
	{
		path: '/admin/login',
		element: <AdminLogin />,
	},
	{
		path: '/admin',
		element: <DashboardLayout />,
		children: [
			{
				path: 'user/list',
				element: <UserList />,
			},
			{
				path: 'user/regist',
				element: <UserRegist />,
			},
			{
				path: 'user/edit/:id',
				element: <UserEdit />,
			},
			{
				path: 'admin/list',
				element: <AdminList />,
			},
			{
				path: 'admin/regist',
				element: <AdminRegist />,
			},
			{
				path: 'admin/edit/:id',
				element: <AdminEdit />,
			},
			{
				path: 'movie/list',
				element: <AdminMovieList />,
			},
			{
				path: 'movie/detail/:id',
				element: <AdminMovieDetail />,
			},
			{
				path: 'movie/regist',
				element: <MovieRegist />,
			},
			{
				path: 'movie/edit/:id',
				element: <MovieEdit />,
			},
			{
				path: 'user/viewlist',
				element: <UserViewList />,
			},
		]
	},
	{
		path: '*',
		element: <UserLogin />,
	}
];

export default routes;
