/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:130f31d4-9a85-49aa-8ab1-09e0a3c79e8f",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_sONTex3DS",
    "aws_user_pools_web_client_id": "72j5qp7cbtt6aml64igo8kkmti",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://ojvvfmizwfginm5h6hgi6xckeq.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "philipsmovie4e84535478c04bf18ba313cf33d02fec151015-prod",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "aws_content_delivery_bucket": "philipsmovie-20210525141137-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "http://philipsmovie-20210525141137-hostingbucket-prod.s3-website-ap-northeast-1.amazonaws.com"
};


export default awsmobile;
