import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Chip, Drawer, Hidden, Link } from '@material-ui/core';
import Logo from './Logo';

const MainSidebar = (props) => {
	const { onMobileClose, openMobile } = props;
	const location = useLocation();

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
	}, [location.pathname]);

	return (
		<Hidden lgUp>
			<Drawer
				anchor="left"
				onClose={onMobileClose}
				open={openMobile}
				variant="temporary"
				PaperProps={{
					sx: {
						backgroundColor: 'background.default',
						width: 256,
					},
				}}
			>
				<Box
					sx={{
						alignItems: 'flex-start',
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
						p: 2,
					}}
				>
					<RouterLink to="/movie/list" style={{ padding: '20px' }}>
						<div
							style={{
								width: '100px',
							}}
						>
							<img
								alt="Logo"
								src="/static/GMC_Wordmark_2008_RGB.jpeg"
								style={{ maxWidth: '100%', height: 'auto' }}
							/>
						</div>
					</RouterLink>
				</Box>
			</Drawer>
		</Hidden>
	);
};

MainSidebar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
};

export default MainSidebar;
