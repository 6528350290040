import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, Hidden } from '@material-ui/core';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import FolderOpen from '../../icons/FolderOpen';
import Image from '../../icons/Image';
import UserAdd from '../../icons/UserAdd';
import Users from '../../icons/Users';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';

const sections = [
	{
		title: '動画管理',
		items: [
			{
				title: '動画一覧',
				path: '/admin/movie/list',
				icon: <FolderOpen fontSize="small" />,
			},
			{
				title: '動画登録',
				path: '/admin/movie/regist',
				icon: <Image fontSize="small" />,
			},
		],
	},
	{
		title: 'ユーザー管理',
		items: [
			{
				title: 'ユーザー一覧',
				path: '/admin/user/list',
				icon: <Users fontSize="small" />,
			},
			{
				title: 'ユーザー登録',
				path: '/admin/user/regist',
				icon: <UserAdd fontSize="small" />,
			},
			{
				title: '管理者一覧',
				path: '/admin/admin/list',
				icon: <Users fontSize="small" />,
			},
			{
				title: '管理者登録',
				path: '/admin/admin/regist',
				icon: <UserAdd fontSize="small" />,
			},
		],
	},
	{
		title: '視聴状況管理',
		items: [
			{
				title: '視聴状況一覧',
				path: '/admin/user/viewlist',
				icon: <ChartSquareBarIcon fontSize="small"/>,
			},
		]
	}
];

const DashboardSidebar = (props) => {
	const { onMobileClose, openMobile } = props;
	const location = useLocation();

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
	}, [location.pathname]);

	const content = (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
			}}
		>
			<Scrollbar options={{ suppressScrollX: true }}>
				<Hidden lgUp>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							p: 2,
						}}
					>
						<RouterLink to="/admin/movie/list" style={{ padding: '20px' }}>
							<div
								style={{
									width: '100px',
								}}
							>
								<img
									alt="Logo"
									src="/static/GMC_Wordmark_2008_RGB.jpeg"
									style={{ maxWidth: '100%', height: 'auto' }}
								/>
							</div>
						</RouterLink>
					</Box>
				</Hidden>
				<Box sx={{ p: 2 }}>
					{sections.map((section) => (
						<NavSection
							key={section.title}
							pathname={location.pathname}
							sx={{
								'& + &': {
									mt: 3,
								},
							}}
							{...section}
						/>
					))}
				</Box>
			</Scrollbar>
		</Box>
	);

	return (
		<>
			<Hidden lgUp>
				<Drawer
					anchor="left"
					onClose={onMobileClose}
					open={openMobile}
					PaperProps={{
						sx: {
							backgroundColor: 'background.paper',
							width: 280,
						},
					}}
					variant="temporary"
				>
					{content}
				</Drawer>
			</Hidden>
			<Hidden lgDown>
				<Drawer
					anchor="left"
					open
					PaperProps={{
						sx: {
							backgroundColor: 'background.paper',
							height: 'calc(100% - 64px) !important',
							top: '64px !Important',
							width: 280,
						},
					}}
					variant="persistent"
				>
					{content}
				</Drawer>
			</Hidden>
		</>
	);
};

DashboardSidebar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
};

export default DashboardSidebar;
