import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Outlet, useNavigate } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import MainNavbar from './MainNavbar';
import MainSidebar from './MainSidebar';
import useAuth from '../hooks/useAuth';

const MainLayoutRoot = experimentalStyled('div')(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	height: '100%',
	paddingTop: 64,
}));

const MainLayout = ({ children }) => {
	const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
	const { user, logout } = useAuth();
	const navigate = useNavigate();
	/**
	 * ユーザログイン確認
	 */
	const checkLogin = async () => {
		// userが空ならログイン画面へ
		if (!user) {
			navigate('/login');
			return;
		}
		console.log('MainLayout.js cognito login user', user);
		console.log(user.username);
		// グループ確認。ユーザでなければログイン画面へ
		const group = await (async () => {
			try {
				return user.signInUserSession.accessToken.payload['cognito:groups'][0];
			} catch (err) {
				await logout();
				navigate('/login');
			}
		})();
		if (group !== 'User') {
			await logout();
			navigate('/login');
		}
	};
	useEffect(async () => {
		await checkLogin();
	}, []);
	return (
		<MainLayoutRoot>
			<MainNavbar onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)} />
			<MainSidebar
				onMobileClose={() => setIsSidebarMobileOpen(false)}
				openMobile={isSidebarMobileOpen}
			/>
			{children || <Outlet />}
		</MainLayoutRoot>
	);
};

MainLayout.propTypes = {
	children: PropTypes.node,
};

export default MainLayout;
