import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
	AppBar,
	Box,
	Hidden,
	IconButton,
	Toolbar,
	Typography,
	Link,
	Divider,
} from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import MenuIcon from '../../icons/Menu';
import useAuth from '../../hooks/useAuth';

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
	...(theme.palette.mode === 'light' && {
		backgroundColor: theme.palette.primary.main,
		boxShadow: 'none',
		color: theme.palette.primary.contrastText,
	}),
	...(theme.palette.mode === 'dark' && {
		backgroundColor: theme.palette.background.paper,
		borderBottom: `1px solid ${theme.palette.divider}`,
		boxShadow: 'none',
	}),
	zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar = (props) => {
	const { onSidebarMobileOpen, ...other } = props;
	const { user, logout } = useAuth();
	const logoutBtnOnClickFnc = async () => {
		await logout();
	};
	return (
		<DashboardNavbarRoot {...other}>
			<Toolbar sx={{ minHeight: 64 }}>
				<Hidden lgUp>
					<IconButton color="inherit" onClick={onSidebarMobileOpen}>
						<MenuIcon fontSize="small" />
					</IconButton>
				</Hidden>
				<Hidden lgDown>
					<RouterLink
						to="/admin/movie/list"
						style={{ backgroundColor: 'white', padding: '20px' }}
					>
						<div
							style={{
								width: '100px',
							}}
						>
							<img
								alt="Logo"
								src="/static/GMC_Wordmark_2008_RGB.jpeg"
								style={{ maxWidth: '100%', height: 'auto' }}
							/>
						</div>
					</RouterLink>
				</Hidden>
				<Box
					sx={{
						flexGrow: 1,
						ml: 2,
					}}
				/>
				<Box sx={{ ml: 1 }}>
					<Typography color="white" variant="body1">
						{user ? user.attributes.username : ''}
					</Typography>
				</Box>
				<Divider
					orientation="vertical"
					sx={{
						height: 32,
						mx: 2,
					}}
				/>
				<Box sx={{ ml: 1 }}>
					<Link
						color="white"
						component={RouterLink}
						onClick={logoutBtnOnClickFnc}
						to="/admin/login"
						underline="none"
						variant="body1"
					>
						ログアウト
					</Link>
				</Box>
			</Toolbar>
		</DashboardNavbarRoot>
	);
};

DashboardNavbar.propTypes = {
	onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
