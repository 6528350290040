/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSignedCookie = /* GraphQL */ `
  query GetSignedCookie($s3key: String!) {
    getSignedCookie(s3key: $s3key)
  }
`;
export const adminsUserRegist = /* GraphQL */ `
  query AdminsUserRegist(
    $email: AWSEmail!
    $name: String!
    $employeeNumber: String!
    $sleepGroup: Int!
    $breathGroup: Int!
    $gssGroup: Int!
    $csGroup: Int!
    $freshmanGroup: Int!
    $userGroups: String!
    $block: String!
    $branch: String!
  ) {
    adminsUserRegist(
      email: $email
      name: $name
      employeeNumber: $employeeNumber
      sleepGroup: $sleepGroup
      breathGroup: $breathGroup
      gssGroup: $gssGroup
      csGroup: $csGroup
      freshmanGroup: $freshmanGroup
      userGroups: $userGroups
      block: $block
      branch: $branch
    )
  }
`;
export const adminsUserRegistCsv = /* GraphQL */ `
  query AdminsUserRegistCsv($s3Bucket: String!, $s3Key: String!) {
    adminsUserRegistCsv(s3Bucket: $s3Bucket, s3Key: $s3Key)
  }
`;
export const adminsUserDelete = /* GraphQL */ `
  query AdminsUserDelete($id: ID!, $email: AWSEmail!) {
    adminsUserDelete(id: $id, email: $email)
  }
`;
export const adminsAdminRegist = /* GraphQL */ `
  query AdminsAdminRegist(
    $email: AWSEmail!
    $name: String!
    $password: String!
    $organization3: String!
  ) {
    adminsAdminRegist(
      email: $email
      name: $name
      password: $password
      organization3: $organization3
    )
  }
`;
export const adminsAdminDelete = /* GraphQL */ `
  query AdminsAdminDelete($id: ID!, $email: AWSEmail!) {
    adminsAdminDelete(id: $id, email: $email)
  }
`;
export const adminsAdminUpdate = /* GraphQL */ `
  query AdminsAdminUpdate(
    $id: ID!
    $email: AWSEmail!
    $name: String!
    $password: String!
    $organization3: String!
  ) {
    adminsAdminUpdate(
      id: $id
      email: $email
      name: $name
      password: $password
      organization3: $organization3
    )
  }
`;
export const adminsDeleteMovie = /* GraphQL */ `
  query AdminsDeleteMovie($id: ID!) {
    adminsDeleteMovie(id: $id)
  }
`;
export const getUsersMovieList = /* GraphQL */ `
  query GetUsersMovieList($userId: ID!) {
    getUsersMovieList(userId: $userId)
  }
`;
export const getUserViewList = /* GraphQL */ `
  query GetUserViewList(
    $sleepGroup: Int
    $breathGroup: Int
    $gssGroup: Int
    $csGroup: Int
    $freshmanGroup: Int
    $block: String
    $branch: String
    $movieName: String
    $movieCategory: String
    $includeViewed: Int
  ) {
    getUserViewList(
      sleepGroup: $sleepGroup
      breathGroup: $breathGroup
      gssGroup: $gssGroup
      csGroup: $csGroup
      freshmanGroup: $freshmanGroup
      block: $block
      branch: $branch
      movieName: $movieName
      movieCategory: $movieCategory
      includeViewed: $includeViewed
    )
  }
`;
export const checkUserNoAuth = /* GraphQL */ `
  query CheckUserNoAuth($id: ID!) {
    checkUserNoAuth(id: $id)
  }
`;
export const userInit = /* GraphQL */ `
  query UserInit($userId: ID!, $password: String!) {
    userInit(userId: $userId, password: $password)
  }
`;
export const passwordResetGetNoAuth = /* GraphQL */ `
  query PasswordResetGetNoAuth($passwordResetId: ID!) {
    passwordResetGetNoAuth(passwordResetId: $passwordResetId)
  }
`;
export const passwordReset = /* GraphQL */ `
  query PasswordReset($userId: ID!, $password: String!) {
    passwordReset(userId: $userId, password: $password)
  }
`;
export const passwordRecovery = /* GraphQL */ `
  query PasswordRecovery($email: AWSEmail!) {
    passwordRecovery(email: $email)
  }
`;
export const getMovie = /* GraphQL */ `
  query GetMovie($id: ID!) {
    getMovie(id: $id) {
      id
      convertJobId
      movieFileName
      name
      detail
      movieCategory
      duration
      relatedFile
      originalMovieS3FullPath
      convertedMovieS3FullPath
      thumbnailS3FullPath
      targetSleepGroup
      targetBreathGroup
      targetGssGroup
      targetCsGroup
      targetFreshmanGroup
      targetGroups
      isMovie
      status
      createdAt
      updatedAt
      convertedAt
      owner
    }
  }
`;
export const listMovies = /* GraphQL */ `
  query ListMovies(
    $id: ID
    $filter: ModelMovieFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMovies(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        convertJobId
        movieFileName
        name
        detail
        movieCategory
        duration
        relatedFile
        originalMovieS3FullPath
        convertedMovieS3FullPath
        thumbnailS3FullPath
        targetSleepGroup
        targetBreathGroup
        targetGssGroup
        targetCsGroup
        targetFreshmanGroup
        targetGroups
        isMovie
        status
        createdAt
        updatedAt
        convertedAt
        owner
      }
      nextToken
    }
  }
`;
export const queryAllMovieList = /* GraphQL */ `
  query QueryAllMovieList(
    $isMovie: Int
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMovieFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryAllMovieList(
      isMovie: $isMovie
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        convertJobId
        movieFileName
        name
        detail
        movieCategory
        duration
        relatedFile
        originalMovieS3FullPath
        convertedMovieS3FullPath
        thumbnailS3FullPath
        targetSleepGroup
        targetBreathGroup
        targetGssGroup
        targetCsGroup
        targetFreshmanGroup
        targetGroups
        isMovie
        status
        createdAt
        updatedAt
        convertedAt
        owner
      }
      nextToken
    }
  }
`;
export const queryOpenMovieList = /* GraphQL */ `
  query QueryOpenMovieList(
    $status: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMovieFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryOpenMovieList(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        convertJobId
        movieFileName
        name
        detail
        movieCategory
        duration
        relatedFile
        originalMovieS3FullPath
        convertedMovieS3FullPath
        thumbnailS3FullPath
        targetSleepGroup
        targetBreathGroup
        targetGssGroup
        targetCsGroup
        targetFreshmanGroup
        targetGroups
        isMovie
        status
        createdAt
        updatedAt
        convertedAt
        owner
      }
      nextToken
    }
  }
`;
export const queryMovieCategoryList = /* GraphQL */ `
  query QueryMovieCategoryList(
    $movieCategory: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMovieFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryMovieCategoryList(
      movieCategory: $movieCategory
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        convertJobId
        movieFileName
        name
        detail
        movieCategory
        duration
        relatedFile
        originalMovieS3FullPath
        convertedMovieS3FullPath
        thumbnailS3FullPath
        targetSleepGroup
        targetBreathGroup
        targetGssGroup
        targetCsGroup
        targetFreshmanGroup
        targetGroups
        isMovie
        status
        createdAt
        updatedAt
        convertedAt
        owner
      }
      nextToken
    }
  }
`;
export const queryMovieGroupList = /* GraphQL */ `
  query QueryMovieGroupList(
    $targetGroups: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMovieFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryMovieGroupList(
      targetGroups: $targetGroups
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        convertJobId
        movieFileName
        name
        detail
        movieCategory
        duration
        relatedFile
        originalMovieS3FullPath
        convertedMovieS3FullPath
        thumbnailS3FullPath
        targetSleepGroup
        targetBreathGroup
        targetGssGroup
        targetCsGroup
        targetFreshmanGroup
        targetGroups
        isMovie
        status
        createdAt
        updatedAt
        convertedAt
        owner
      }
      nextToken
    }
  }
`;
export const querySleepGroupMovieList = /* GraphQL */ `
  query QuerySleepGroupMovieList(
    $targetSleepGroup: Int
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMovieFilterInput
    $limit: Int
    $nextToken: String
  ) {
    querySleepGroupMovieList(
      targetSleepGroup: $targetSleepGroup
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        convertJobId
        movieFileName
        name
        detail
        movieCategory
        duration
        relatedFile
        originalMovieS3FullPath
        convertedMovieS3FullPath
        thumbnailS3FullPath
        targetSleepGroup
        targetBreathGroup
        targetGssGroup
        targetCsGroup
        targetFreshmanGroup
        targetGroups
        isMovie
        status
        createdAt
        updatedAt
        convertedAt
        owner
      }
      nextToken
    }
  }
`;
export const queryBreathGroupMovieList = /* GraphQL */ `
  query QueryBreathGroupMovieList(
    $targetBreathGroup: Int
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMovieFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryBreathGroupMovieList(
      targetBreathGroup: $targetBreathGroup
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        convertJobId
        movieFileName
        name
        detail
        movieCategory
        duration
        relatedFile
        originalMovieS3FullPath
        convertedMovieS3FullPath
        thumbnailS3FullPath
        targetSleepGroup
        targetBreathGroup
        targetGssGroup
        targetCsGroup
        targetFreshmanGroup
        targetGroups
        isMovie
        status
        createdAt
        updatedAt
        convertedAt
        owner
      }
      nextToken
    }
  }
`;
export const queryGssGroupMovieList = /* GraphQL */ `
  query QueryGssGroupMovieList(
    $targetGssGroup: Int
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMovieFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryGssGroupMovieList(
      targetGssGroup: $targetGssGroup
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        convertJobId
        movieFileName
        name
        detail
        movieCategory
        duration
        relatedFile
        originalMovieS3FullPath
        convertedMovieS3FullPath
        thumbnailS3FullPath
        targetSleepGroup
        targetBreathGroup
        targetGssGroup
        targetCsGroup
        targetFreshmanGroup
        targetGroups
        isMovie
        status
        createdAt
        updatedAt
        convertedAt
        owner
      }
      nextToken
    }
  }
`;
export const queryCsGroupMovieList = /* GraphQL */ `
  query QueryCsGroupMovieList(
    $targetCsGroup: Int
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMovieFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryCsGroupMovieList(
      targetCsGroup: $targetCsGroup
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        convertJobId
        movieFileName
        name
        detail
        movieCategory
        duration
        relatedFile
        originalMovieS3FullPath
        convertedMovieS3FullPath
        thumbnailS3FullPath
        targetSleepGroup
        targetBreathGroup
        targetGssGroup
        targetCsGroup
        targetFreshmanGroup
        targetGroups
        isMovie
        status
        createdAt
        updatedAt
        convertedAt
        owner
      }
      nextToken
    }
  }
`;
export const queryFreshmanGroupMovieList = /* GraphQL */ `
  query QueryFreshmanGroupMovieList(
    $targetFreshmanGroup: Int
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMovieFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryFreshmanGroupMovieList(
      targetFreshmanGroup: $targetFreshmanGroup
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        convertJobId
        movieFileName
        name
        detail
        movieCategory
        duration
        relatedFile
        originalMovieS3FullPath
        convertedMovieS3FullPath
        thumbnailS3FullPath
        targetSleepGroup
        targetBreathGroup
        targetGssGroup
        targetCsGroup
        targetFreshmanGroup
        targetGroups
        isMovie
        status
        createdAt
        updatedAt
        convertedAt
        owner
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      name
      employeeNumber
      sleepGroup
      breathGroup
      gssGroup
      csGroup
      freshmanGroup
      block
      branch
      userGroups
      isUser
      isTmpUser
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        email
        name
        employeeNumber
        sleepGroup
        breathGroup
        gssGroup
        csGroup
        freshmanGroup
        block
        branch
        userGroups
        isUser
        isTmpUser
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const queryUserList = /* GraphQL */ `
  query QueryUserList(
    $isUser: Int
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryUserList(
      isUser: $isUser
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        employeeNumber
        sleepGroup
        breathGroup
        gssGroup
        csGroup
        freshmanGroup
        block
        branch
        userGroups
        isUser
        isTmpUser
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const queryUserGetByEmail = /* GraphQL */ `
  query QueryUserGetByEmail(
    $email: AWSEmail
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryUserGetByEmail(
      email: $email
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        employeeNumber
        sleepGroup
        breathGroup
        gssGroup
        csGroup
        freshmanGroup
        block
        branch
        userGroups
        isUser
        isTmpUser
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const queryBlockUserList = /* GraphQL */ `
  query QueryBlockUserList(
    $block: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryBlockUserList(
      block: $block
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        employeeNumber
        sleepGroup
        breathGroup
        gssGroup
        csGroup
        freshmanGroup
        block
        branch
        userGroups
        isUser
        isTmpUser
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const queryBranchUserList = /* GraphQL */ `
  query QueryBranchUserList(
    $branch: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryBranchUserList(
      branch: $branch
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        employeeNumber
        sleepGroup
        breathGroup
        gssGroup
        csGroup
        freshmanGroup
        block
        branch
        userGroups
        isUser
        isTmpUser
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const querySleepGroupUserList = /* GraphQL */ `
  query QuerySleepGroupUserList(
    $sleepGroup: Int
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    querySleepGroupUserList(
      sleepGroup: $sleepGroup
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        employeeNumber
        sleepGroup
        breathGroup
        gssGroup
        csGroup
        freshmanGroup
        block
        branch
        userGroups
        isUser
        isTmpUser
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const queryBreathGroupUserList = /* GraphQL */ `
  query QueryBreathGroupUserList(
    $breathGroup: Int
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryBreathGroupUserList(
      breathGroup: $breathGroup
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        employeeNumber
        sleepGroup
        breathGroup
        gssGroup
        csGroup
        freshmanGroup
        block
        branch
        userGroups
        isUser
        isTmpUser
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const queryGssGroupUserList = /* GraphQL */ `
  query QueryGssGroupUserList(
    $gssGroup: Int
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryGssGroupUserList(
      gssGroup: $gssGroup
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        employeeNumber
        sleepGroup
        breathGroup
        gssGroup
        csGroup
        freshmanGroup
        block
        branch
        userGroups
        isUser
        isTmpUser
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const queryCsGroupUserList = /* GraphQL */ `
  query QueryCsGroupUserList(
    $csGroup: Int
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryCsGroupUserList(
      csGroup: $csGroup
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        employeeNumber
        sleepGroup
        breathGroup
        gssGroup
        csGroup
        freshmanGroup
        block
        branch
        userGroups
        isUser
        isTmpUser
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const queryFreshmanGroupUserList = /* GraphQL */ `
  query QueryFreshmanGroupUserList(
    $freshmanGroup: Int
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryFreshmanGroupUserList(
      freshmanGroup: $freshmanGroup
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        employeeNumber
        sleepGroup
        breathGroup
        gssGroup
        csGroup
        freshmanGroup
        block
        branch
        userGroups
        isUser
        isTmpUser
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getAdmin = /* GraphQL */ `
  query GetAdmin($id: ID!) {
    getAdmin(id: $id) {
      id
      email
      name
      isAdmin
      organization3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAdmins = /* GraphQL */ `
  query ListAdmins(
    $id: ID
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAdmins(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        email
        name
        isAdmin
        organization3
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const queryAdminList = /* GraphQL */ `
  query QueryAdminList(
    $isAdmin: Int
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryAdminList(
      isAdmin: $isAdmin
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        isAdmin
        organization3
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const queryAdminGetByEmail = /* GraphQL */ `
  query QueryAdminGetByEmail(
    $email: AWSEmail
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryAdminGetByEmail(
      email: $email
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        isAdmin
        organization3
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const queryOrganization3AdminList = /* GraphQL */ `
  query QueryOrganization3AdminList(
    $organization3: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryOrganization3AdminList(
      organization3: $organization3
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        isAdmin
        organization3
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPasswordReset = /* GraphQL */ `
  query GetPasswordReset($id: ID!) {
    getPasswordReset(id: $id) {
      id
      userId
      createdAt
      expiredAt
      user {
        items {
          id
          email
          name
          employeeNumber
          sleepGroup
          breathGroup
          gssGroup
          csGroup
          freshmanGroup
          block
          branch
          userGroups
          isUser
          isTmpUser
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const listPasswordResets = /* GraphQL */ `
  query ListPasswordResets(
    $id: ID
    $filter: ModelPasswordResetFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPasswordResets(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        createdAt
        expiredAt
        user {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const getWatched = /* GraphQL */ `
  query GetWatched($id: ID!) {
    getWatched(id: $id) {
      id
      userId
      movieId
      createdAt
      updatedAt
      movie {
        items {
          id
          convertJobId
          movieFileName
          name
          detail
          movieCategory
          duration
          relatedFile
          originalMovieS3FullPath
          convertedMovieS3FullPath
          thumbnailS3FullPath
          targetSleepGroup
          targetBreathGroup
          targetGssGroup
          targetCsGroup
          targetFreshmanGroup
          targetGroups
          isMovie
          status
          createdAt
          updatedAt
          convertedAt
          owner
        }
        nextToken
      }
      user {
        items {
          id
          email
          name
          employeeNumber
          sleepGroup
          breathGroup
          gssGroup
          csGroup
          freshmanGroup
          block
          branch
          userGroups
          isUser
          isTmpUser
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const listWatcheds = /* GraphQL */ `
  query ListWatcheds(
    $id: ID
    $filter: ModelWatchedFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWatcheds(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        movieId
        createdAt
        updatedAt
        movie {
          nextToken
        }
        user {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const queryWatchedUser = /* GraphQL */ `
  query QueryWatchedUser(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWatchedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryWatchedUser(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        movieId
        createdAt
        updatedAt
        movie {
          nextToken
        }
        user {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const queryWatchedUserMovie = /* GraphQL */ `
  query QueryWatchedUserMovie(
    $userId: ID
    $movieId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWatchedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryWatchedUserMovie(
      userId: $userId
      movieId: $movieId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        movieId
        createdAt
        updatedAt
        movie {
          nextToken
        }
        user {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const queryWatchedMovie = /* GraphQL */ `
  query QueryWatchedMovie(
    $movieId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWatchedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryWatchedMovie(
      movieId: $movieId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        movieId
        createdAt
        updatedAt
        movie {
          nextToken
        }
        user {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const getMstBlockBranch = /* GraphQL */ `
  query GetMstBlockBranch($id: ID!) {
    getMstBlockBranch(id: $id) {
      id
      blockCode
      blockName
      branchCode
      branchName
      owner
    }
  }
`;
export const listMstBlockBranchs = /* GraphQL */ `
  query ListMstBlockBranchs(
    $id: ID
    $filter: ModelMstBlockBranchFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMstBlockBranchs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        blockCode
        blockName
        branchCode
        branchName
        owner
      }
      nextToken
    }
  }
`;
export const getMstOrganization3 = /* GraphQL */ `
  query GetMstOrganization3($id: ID!) {
    getMstOrganization3(id: $id) {
      id
      organization3Code
      organization3Name
      owner
    }
  }
`;
export const listMstOrganization3s = /* GraphQL */ `
  query ListMstOrganization3s(
    $id: ID
    $filter: ModelMstOrganization3FilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMstOrganization3s(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organization3Code
        organization3Name
        owner
      }
      nextToken
    }
  }
`;
