import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Amplify, { API, Auth, graphqlOperation } from 'aws-amplify';
// import { amplifyConfig } from '../config';
// Amplify.configure(amplifyConfig);
import { queryAdminGetByEmail, queryUserGetByEmail } from '../graphql/queries';
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  mstBlock: [],
  mstBranch: []
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state) => ({ ...state }),
  VERIFY_CODE: (state) => ({ ...state }),
  RESEND_CODE: (state) => ({ ...state }),
  PASSWORD_RECOVERY: (state) => ({ ...state }),
  PASSWORD_RESET: (state) => ({ ...state })
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'Amplify',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  /**
   * ログインしたユーザの情報を取得
   * @param user
   * @returns {Promise<*>}
   */
  const getLoginInfo = async (user) => {
    console.log('getLoginInfo');
    const group = user.signInUserSession.accessToken.payload['cognito:groups'][0];
    console.log(group);
    try {
      console.log(user);
      if (group === 'Admin') {
        console.log('Admin Login');
        const res = await API.graphql(graphqlOperation(queryAdminGetByEmail, { email: user.attributes.email.replace('.adminslogin', '') }));
        return res.data.queryAdminGetByEmail.items[0];
      } else if (group === 'User') {
        console.log('User Login');
        const res = await API.graphql(graphqlOperation(queryUserGetByEmail, { email: user.attributes.email }));
        return res.data.queryUserGetByEmail.items[0];
      }
    } catch (err) {
      console.error(err);
      await logout();
    }
  };
  useEffect(() => {
    const initialize = async () => {
      console.log('----- initialize -----');
      try {
        const user = await Auth.currentAuthenticatedUser();
        // Here you should extract the complete user profile to make it
        // available in your entire app.
        // The auth state only provides basic information.
        // ユーザ情報取得
        Amplify.configure(awsconfig);
        const loginInfo = await getLoginInfo(user);
        user.attributes.username = loginInfo.name;
        user.attributes.userId = loginInfo.id;
        user.currentUserInfo = await Auth.currentUserInfo();
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: user
          }
        });
      } catch (error) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };
    initialize();
  }, []);

  const login = async (email, password) => {
    console.log('----- login -----');
    const user = await Auth.signIn(email, password);
    if (user.challengeName) {
      console.error(`Unable to login, because challenge "${user.challengeName}" is mandated and we did not handle this case.`);
      return;
    }
    console.log('login success!');
    // ユーザ情報取得
    Amplify.configure(awsconfig);
    const loginInfo = await getLoginInfo(user);
    user.attributes.username = loginInfo.name;
    user.attributes.userId = loginInfo.id;
    user.currentUserInfo = await Auth.currentUserInfo();
    dispatch({
      type: 'LOGIN',
      payload: {
        user: user
      }
    });
    return user;
  };

  const logout = async () => {
    await Auth.signOut();
    dispatch({
      type: 'LOGOUT'
    });
  };

  const register = async (email, password) => {
    await Auth.signUp({
      username: email,
      password,
      attributes: { email }
    });
    dispatch({
      type: 'REGISTER'
    });
  };

  const verifyCode = async (username, code) => {
    await Auth.confirmSignUp(username, code);
    dispatch({
      type: 'VERIFY_CODE'
    });
  };

  const resendCode = async (username) => {
    await Auth.resendSignUp(username);
    dispatch({
      type: 'RESEND_CODE'
    });
  };

  const passwordRecovery = async (username) => {
    await Auth.forgotPassword(username);
    dispatch({
      type: 'PASSWORD_RECOVERY'
    });
  };

  const passwordReset = async (username, code, newPassword) => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    dispatch({
      type: 'PASSWORD_RESET'
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Amplify',
        login,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
